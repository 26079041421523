<template>
  <div class="container page">
    <van-nav-bar title="Cài đặt ngân hàng" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">Vui lòng thêm thông tin ngân hàng</div>
      <van-cell-group>
        <van-field v-model="name" label="Tên chủ tài khoản" type="text" placeholder="Nhập tên chủ tài khoản"/>
        <van-field v-model="bankid" label="Số tài khoản" type="digit" placeholder="Nhập số tài khoản" />
        <van-field v-model="bank" label="Tên ngân hàng" type="text" placeholder="Nhập tên ngân hàng" />
        <!--<van-field v-model="bank" label="Ngân hàng" readonly placeholder="Chọn ngân hàng" @click="showSelectBanks()"/>-->
      </van-cell-group>
      <p>Để đảm bảo an toàn cho tài khoản của bạn, vui lòng liên kết họ tên thật của bạn và đặt mật khẩu rút điểm. Nếu tên của bạn không khớp với tên tài khoản, bạn sẽ không thể rút điểm</p>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()">Xác nhận</van-button>
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }" >
      <van-picker
          show-toolbar
          :columns="banks"
          @confirm="onConfirm"
          @cancel="onCancel"
          confirm-button-text="Chọn"
          cancel-button-text="Hủy"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banks: [],
      showBank:false,
      userInfo:{},
      name:"",
      bankid:"",
      bank:"",
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    bindCard(){
      if(this.name === "" || this.name === null || this.name === undefined){
        this.$toast("Vui lòng nhập tên chủ tài khoản!");
        return true;
      }
      if(this.bankid === "" || this.bankid === null || this.bankid === undefined){
        this.$toast("Vui lòng nhập số tài khoản!");
        return true;
      }
      if(this.bank === "" || this.bank === null || this.bank === undefined){
        this.$toast.fail("Vui lòng chọn ngân hàng！");
        return false;
      }
      this.$http({
        method: 'post',
        data:{name:this.name,bankid:this.bankid,bank:this.bank},
        url: 'user_set_bank'
      }).then(res=>{
        if(res.code === 200){
          this.$router.push({path:'/Mine'})
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    showSelectBanks(){
      this.showBank = true;
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getBankList(){
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res=>{
        if(res.code === 200){
          this.banks = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.value
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 16px;
  line-height: 50px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.main-box{
  background: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 16px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-field__label {
  width: 130px;
}
::v-deep .van-picker__cancel, ::v-deep .van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 20px;
}
::v-deep .van-popup--bottom {
  max-width: 600px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0;
}
.main-box p{
  padding: 0 20px;
  font-size: 16px;
  color: #ee0a24;
}
.bindCard {
  margin: 10px 20px 0;
  height: 40px;
  line-height: 1.22667px;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#c24491,#775fd9);
}
</style>
