<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="Thiên Địa Mỹ Nhân" />
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab title="Tỉnh/Thành phố">
					<div class="card">Lưu ý: Nền tảng ứng dụng hẹn hò thông qua trực tuyến. Để đảm bảo quyền riêng tư cá nhân của người dùng, chỉ có thể tham gia với tư cách thành viên bằng cách liên hệ với chuyên viên để được sắp xếp lịch hẹn hò thành công sớm nhất。</div>
					<div class="address">
						<van-row type="flex" justify="" v-for="(val, key) in addlist" :key="key">
							<van-col span="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row>
					</div>
				</van-tab>
				<van-tab title="Giới thiệu nền tảng">
					<div class="card">Lưu ý: Nền tảng ứng dụng hẹn hò thông qua trực tuyến. Để đảm bảo quyền riêng tư cá nhân của người dùng, chỉ có thể tham gia với tư cách thành viên bằng cách liên hệ với chuyên viên để được sắp xếp lịch hẹn hò thành công sớm nhất。</div>
					<div class="rig-box">
						<p class="rig-title">Có những người nào?</p>
						<p class="rig-content">Người nổi tiếng trên mạng, người mẫu, tiếp viên hàng không, người mẫu trẻ, sinh viên đại học, chỉ có điều bạn không nghĩ ra được và bạn không thể làm được nếu không có nền tảng này</p>
						<p class="rig-title">Khu vực phục vụ?</p>
						<p class="rig-content">Các cuộc hẹn miễn phí trong cùng một thành phố, hoặc ngoài thành phố có thể được sắp xếp bằng cách liên hệ với nhân viên hỗ trợ。</p>
						<p class="rig-content">Lưu ý: Nền tảng ứng dụng hẹn hò thông qua trực tuyến. Để đảm bảo quyền riêng tư cá nhân của người dùng, chỉ có thể tham gia với tư cách thành viên bằng cách liên hệ với chuyên viên để được sắp xếp lịch hẹn hò thành công sớm nhất。</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: 'Hồ Chí Minh',
					1: 'Hà Nội',
					2: 'Đà Nẵng',
					3: 'Vũng Tàu'
				},
				{
					0: 'Quy Nhơn',
					1: 'Đà Lạt',
					2: 'Hải Phòng',
					3: 'Bắc Ninh'
				}
			]
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style lang="less" scoped>
.page {
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg, #775fd9, #c24491);
	height: 50px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-col {
	padding: 0!important;
	width: 50%;
	text-align: center;
}
::v-deep .van-nav-bar__title {
	max-width: 80%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 18px;
}
::v-deep .van-nav-bar__content {
	height: 50px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 16px;
	line-height: 40px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #775fd9;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 40px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 10px;
	width: 95%;
	color: white;
	font-size: 15px;
	margin: 20px auto 10px auto;
	border-radius: 10px;
	line-height: 1.3;
}
::v-deep .van-row--flex {
	line-height: 40px;
	flex-flow: row wrap;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #d161ac;
	font-size: 18px;
	margin: 25px 0 5px
}
.rig-content {
	font-size: 17px;
	// margin-top: 10px;
}
.address {
	width: 90%;
	margin: 0 auto;
}
.convention-item {
	background: #f2f2f5;
	padding-bottom: 100px;
}
</style>
