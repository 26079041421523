import Vue from 'vue'
import VueRouter from 'vue-router'
//Trang chủ
import Home from '../pages/home/index.vue'/* Trang chủ */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Choose from '../pages/choose/index.vue'/* Thiên Địa Mỹ Nhân */
import List from '../pages/choose/list.vue'/* Thiên Địa Mỹ Nhân列表 */
import Profile from '../pages/choose/profile.vue'/* Thiên Địa Mỹ Nhân详情 */
import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */
import Login from '../pages/login/index.vue'/* Đăng nhập */
import Register from '../pages/login/register.vue'/* Đăng ký */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* Thông Tin Cá Nhân */
import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改姓名 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改提现密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
import PlayVideo  from '../pages/video/PlayVideo'/* Xem video页面 */
import Setbank  from '../pages/mine/Setbank'/* Xem video页面 */
import BindCard  from '../pages/mine/BindCard'/* 绑定银行卡界面 */
import Recharge from '../pages/mine/Recharge'
import Withdraw  from '../pages/mine/Withdraw'/* 绑定银行卡界面 */
import Personalreport  from '../pages/mine/Personalreport'/* 个人表报 */
import GameRecord  from '../pages/mine/GameRecord'/* 游戏记录 */
import WithdrawRecord  from '../pages/mine/WithdrawRecord'/* 提现记录 */






Vue.use(VueRouter)
const routes = [
    {path:'/',redirect:'/Home',component:Home,meta:{title:'Trang chủ'}},
    {path:'/Home',name:'home',component:Home,meta:{title:'Trang chủ'}},
	{path:'/Choose',name:'choose',component:Choose,meta:{title:'Thiên Địa Mỹ Nhân'}},
	{path:'/List',name:'list',component:List,meta:{title:'Danh sách'}},
	{path:'/Profile',name:'profile',component:Profile,meta:{title:'Trang cá nhân'}},
    {path:'/Mine',name:'mine',component:Mine,meta:{title:'Hồ sơ'}},
    {path:'/Video',name:'video',component:Video,meta:{title:'Rạp chiếu phim'}},
    {path:'/Game',name:'game',component:Game,meta:{title:'Sảnh bình chọn'}},
    {path:'/Login',name:'login',component:Login,meta:{title:'Đăng nhập'}},
    {path:'/Register',name:'register',component:Register,meta:{title:'Đăng ký'}},
    {path:'/ServiceOnline',name:'ServiceOnline',component:ServiceOnline,meta:{title:'Hỗ trợ trực tuyến'}},
    {path:'/ServicePage',name:'ServicePage',component:ServicePage,meta:{title:'Hỗ trợ trực tuyến'}},
    {path:'/Setting',name:'Setting',component:Setting,meta:{title:'Cài đặt'}},
    {path:'/Infomation',name:'Infomation',component:Infomation,meta:{title:'Thông Tin Cá Nhân'}},
    {path:'/Setname',name:'Setname',component:Setname,meta:{title:'Sửa tên'}},
    {path:'/Setsex',name:'Setsex',component:Setsex,meta:{title:'Sửa giới tính'}},
    {path:'/SetPayPassword',name:'SetPayPassword',component:SetPayPassword,meta:{title:'Sửa mật khẩu rút điểm'}},
    {path:'/SetLoginPassword',name:'SetLoginPassword',component:SetLoginPassword,meta:{title:'Đổi mật khẩu'}},
    {path:'/Lottery',name:'Lottery',component:Lottery,meta:{title:'Bình chọn'}},
    {path:'/Notice',name:'Notice',component:Notice,meta:{title:'Thông báo'}},
    {path:'/PlayVideo',name:'PlayVideo',component:PlayVideo,meta:{title:'Xem video'}},
    {path:'/Setbank',name:'Setbank',component:Setbank,meta:{title:'Cài đặt ngân hàng'}},
    {path:'/BindCard',name:'BindCard',component:BindCard,meta:{title:'Cài đặt ngân hàng'}},
    {path:'/Withdraw',name:'Withdraw',component:Withdraw,meta:{title:'Rút điểm'}},
    {path:'/Recharge',name:'Recharge',component:Recharge,meta:{title:'Nạp điểm'}},
    {path:'/Personalreport',name:'Personalreport',component:Personalreport,meta:{title:'Chi tiết tài khoản'}},
    {path:'/WithdrawRecord',name:'WithdrawRecord',component:WithdrawRecord,meta:{title:'Lịch sử rút điểm'}},
    {path:'/GameRecord',name:'GameRecord',component:GameRecord,meta:{title:'Lịch sử bình chọn'}},


];

//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach((to,from,next) => {         //sửa đổi cấu hình định tuyến tiêu đề thêm cái này
    document.title = to.matched[0].meta.title
    next()
})

export default router